<template>
  <div>
    <SpFilterForm :model="formData" @onSearch="onSearch" @onReset="onReset">
      <SpFilterFormItem prop="coupon_no">
        <el-input v-model="formData.coupon_no" placeholder="请输入券code" />
      </SpFilterFormItem>
      <SpFilterFormItem prop="coupon_name">
        <el-input v-model="formData.coupon_name" placeholder="请输入券名称" />
      </SpFilterFormItem>
    </SpFilterForm>

    <el-tabs v-model="formData.coupon_type" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabsList" :key="item.name" :label="item.label" :name="item.name"></el-tab-pane>
    </el-tabs>

    <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="200" :row-actions-align="'left'"
      :fixed-row-action="true" :url="url" :split-count="4" :hooks="{ beforeSearch: beforeSearch }" />

  </div>
</template>

<script>
const COUPON_TYPE = {
  'full_minus': {
    label: '满减',
    name: 'full_minus'
  },
  'repeat_full_minus': {
    label: '每满减',
    name: 'repeat_full_minus'
  },
  'full_discount_price': {
    label: '满金额折扣',
    name: 'full_discount_price'
  },
  'fill_discount_num': {
    label: '满件数折扣',
    name: 'fill_discount_num'
  }
}

import Vue from 'vue'
import { createSetting, useDialog } from '@shopex/finder'

export default {
  data() {
    return {
      tabsList: [
        {
          label: '全部',
          name: '0'
        },
        ...Object.values(COUPON_TYPE)
      ],
      formData: {
        coupon_no: '',
        coupon_name: '',
        coupon_type: '0'
      },
    }
  },
  computed: {
    url() {
      return `/discountcard/crm/list`
    },
    setting() {
      return createSetting({
        columns: [
          { name: '券Code', key: 'coupon_no' },
          {
            name: '券类型', key: 'coupon_type',
            render: (h, { row }) => {
              return (
                <div>{COUPON_TYPE[row.coupon_type].label}</div>
              )
            }
          },
          {
            name: '券信息', key: 'coupon_describe',
            render: (h, { row }) => {
              return (
                <div>
                  <div style='color: #176721;'>{row.coupon_name}</div>
                  <div>{row.coupon_describe}</div>
                </div>
              )
            }
          },
          {
            name: '使用门槛', key: 'conditions',
            render: (h, { row }) => {
              return (
                <div style='color: #176721;'>{this.conditionsText(row)}</div>
              )
            }
          },
          {
            name: '抵扣', key: 'rewards',
            render: (h, { row }) => {
              return (
                <div style='color: #176721;'>{this.rewardsText(row)}</div>
              )
            }
          },
          {
            name: '适用范围', key: 'item_name',
            render: (h, { row }) => {
              return (
                <div>
                  <span style='color: #176721;margin-right: 10px'>{row.limit_goods_type == 'none' ? '不限商品' : '指定商品'}</span>
                  <span style='color: #176721;'>{row.limit_shop_type == 'all' ? '不限店铺' : '指定店铺'}</span>
                </div>
              )
            }
          },
        ],
        actions: [
          {
            name: '查看适用门店',
            key: 'priview',
            type: 'button',
            buttonType: 'text',
            size: 'small',
            action: {
              handler(row) {
                useDialog(null, {
                  no: row[0].coupon_no,
                  title: `查看适用门店`,
                  width: '50%',
                  component: () => import('./comps/crm-shop-list.vue'),
                })
              }
            }
          },
          {
            name: '查看商品列表',
            key: 'priview',
            type: 'button',
            buttonType: 'text',
            size: 'small',
            action: {
              handler(row) {
                useDialog(null, {
                  no: row[0].coupon_no,
                  title: `查看商品列表`,
                  width: '50%',
                  component: () => import('./comps/crm-goods-list.vue'),
                })
              }
            }
          },
        ]
      })
    },
  },
  methods: {
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onReset() {
      this.formData = {
        coupon_no: '',
        coupon_name: '',
        coupon_type: '0'
      }
      this.onSearch()
    },
    beforeSearch(param) {
      let params = {
        ...this.formData,
        ...param,
      }
      if (params.coupon_type === '0') {
        delete params.coupon_type
      }
      return params
    },
    handleClick(tab, event) {
      this.onSearch()
    },
    conditionsText(row) {
      const textMap = {
        repeat_full_minus: `每满${row.conditions}元`,
        fill_discount_num: `满${row.conditions}件`,
      };
      return textMap[row.coupon_type] || `满${row.conditions}元`;
    },
    rewardsText(row) {
      return row.coupon_type === 'full_discount_price' || row.coupon_type === 'fill_discount_num'
        ? `满${row.rewards}折`
        : `满${row.rewards}元`;
    }
  }
}
</script>

<style lang="scss" scoped>
.item_name {
  color: #409EFF;
}
</style>
